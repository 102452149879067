import { Link, useNavigate } from "react-router-dom";
import { Layout, Menu } from "antd";
import { DivWrapper } from "./MainLaout.styled";
import { Outlet, useLocation } from "react-router-dom";
import react, { useState, useEffect } from "react";

const { Header, Content, Footer } = Layout;

function MainLayout() {
  const location = useLocation();
  const [defaultSelect, setDefaultSelect] = useState(1);
  const getDefaultKey = () => {
    console.log("location:", location);
    const arr = {
      "/": 1,
      "/yestorday": 2,
      "/search": 3,
      "/analysis": 4,
      "/datascreen": 5,
      "/datatransfer": 6,
      "/about": 7,
    };
    setDefaultSelect(arr[location.pathname]);
  };
  useEffect(() => {
    getDefaultKey();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const navigate = useNavigate();
  const logout = () => {
    localStorage.clear();
    navigate("/login");
  };
  const isShowLogout = () => {
    const isLogin = localStorage.getItem("userToken");
    if (isLogin) {
      return (
        <Menu.Item key={8} onClick={logout}>
          <Link to="about">退出</Link>
        </Menu.Item>
      );
    } else {
      return <react.Fragment></react.Fragment>;
    }
  };
  return (
    <DivWrapper>
      <Layout className="layout">
        <Header>
          <div className="logo">泰州京城环保</div>
          <Menu
            theme="dark"
            mode="horizontal"
            defaultSelectedKeys={[defaultSelect]}
          >
            <Menu.Item key={1}>
              <Link to="/">首页</Link>
            </Menu.Item>
            <Menu.Item key={2}>
              <Link to="yestorday">昨日数据</Link>
            </Menu.Item>
            <Menu.Item key={3}>
              <Link to="search">数据查询</Link>
            </Menu.Item>
            <Menu.Item key={4}>
              <Link to="analysis">数据分析</Link>
            </Menu.Item>
            <Menu.Item key={5}>
              <Link to="datascreen" target="_blank">
                数据大屏
              </Link>
            </Menu.Item>
            <Menu.Item key={6}>
              <Link to="datatransfer">数据上传</Link>
            </Menu.Item>
            <Menu.Item key={7}>
              <Link to="about">关于</Link>
            </Menu.Item>
            {isShowLogout()}
          </Menu>
        </Header>
        <Content className="mainContent">
          <Outlet />
        </Content>
        <Footer style={{ textAlign: "center" }}>
          泰州京城环保产业有限公司 ©2021
        </Footer>
      </Layout>
    </DivWrapper>
  );
}
export default MainLayout;
