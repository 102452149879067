import styled from "styled-components";
export const DivWrapper = styled.div`
  text-align: center;
  .formCenter{
    margin-top: 100px;
    display: inline-block;
    width: 350px;
  }
  .login-form{
    max-width: 350px; 
    text-align: left;
  }
  .buttonWrapper{
    text-align: center;
  }
  .errorMsg{
    color: red;
    font-size: 14px;
    text-align: left;
  }
`