import { Routes, Route } from "react-router-dom";
import { DivWrapper } from "./App.styled";
import React from "react";
import DataTransfer from "./pages/DataTransfer";
import Login from "./pages/Login";
import PrivateRoute from "./pages/PrivateRoute";
import MainLaout from "./pages/MainLaout";
import NotFound from "./pages/NotFound";
import Loading from "./pages/Loading";

const Analysis = React.lazy(() =>
  import(/* webpackChunkName: "Analysis" */ "./pages/Analysis")
);
const Search = React.lazy(() =>
  import(/* webpackChunkName: "Search" */ "./pages/Search")
);
const Home = React.lazy(() =>
  import(/* webpackChunkName: "Home" */ "./pages/Home")
);
const DataScreen = React.lazy(() =>
  import(/* webpackChunkName: "DataScreen" */ "./pages/DataScreen")
);
const Yestorday = React.lazy(() =>
  import(/* webpackChunkName: "Yestorday" */ "./pages/Yestorday")
);
const About = React.lazy(() =>
  import(/* webpackChunkName: "About" */ "./pages/About")
);
function App() {
  return (
    <DivWrapper>
      <Routes>
        <Route path="/" element={<MainLaout />}>
          <Route path="*" element={<NotFound />} />

          <Route
            index
            path="/"
            element={
              <PrivateRoute>
                <React.Suspense fallback={<Loading />}>
                  <Home />
                </React.Suspense>
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="yestorday"
            element={
              <PrivateRoute>
                <React.Suspense fallback={<Loading />}>
                  <Yestorday />
                </React.Suspense>
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="search"
            element={
              <PrivateRoute>
                <React.Suspense fallback={<Loading />}>
                  <Search />
                </React.Suspense>
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="analysis"
            element={
              <PrivateRoute>
                <React.Suspense fallback={<Loading />}>
                  <Analysis />
                </React.Suspense>
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="datatransfer"
            element={
              <PrivateRoute>
                <DataTransfer />
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="about"
            element={
              <PrivateRoute>
                <React.Suspense fallback={<Loading />}>
                  <About />
                </React.Suspense>
              </PrivateRoute>
            }
          ></Route>
          <Route path="login" element={<Login />}></Route>
        </Route>
        <Route
          path="datascreen"
          element={
            <PrivateRoute>
              <React.Suspense fallback={<Loading />}>
                <DataScreen />
              </React.Suspense>
            </PrivateRoute>
          }
        ></Route>
      </Routes>
    </DivWrapper>
  );
}
export default App;
