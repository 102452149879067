import React,{useState} from "react";
import { Form, Input, Button } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import {useNavigate} from 'react-router-dom'
import { DivWrapper } from "./styled";
import {post} from '../../request/request'
import { LoginUri } from "../../request/uri";
export default function Login() {
  let navigate = useNavigate()
  const [errmsg, setErrmsg] = useState('')
  //  发送登录请求
  const onFinish = (values) => {
    post(LoginUri, values).then((resp) => {
      if(resp.code !== 200){
        // 登录错误
        setErrmsg(resp.message)
      }else{
        // 保存用户信息到localstorage
        setErrmsg('')
        console.log(resp);
        localStorage.setItem('userToken',resp.data.token)
        localStorage.setItem('username',resp.data.username)
        navigate('/')
      }
    });
  };
  return (
    <DivWrapper>
      <div className="formCenter">
      <h2>登录</h2>
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <Form.Item
          name="username"
          rules={[{ required: true, message: "请输入用户名" }]}
        >
          <Input
            size="large"
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="用户名"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: "请输入密码" }]}
        >
          <Input
            size="large"
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="密码"
          />
        </Form.Item>
        <Form.Item className="buttonWrapper">
          <div className="errorMsg">{errmsg}</div>
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            className="login-form-button"
          >
            登录
          </Button>
        </Form.Item>
      </Form>
      </div>
    </DivWrapper>
  );
}
