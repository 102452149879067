import React from "react";
import { Result, Button } from "antd";
import { useNavigate } from "react-router-dom";
export default function NotFound() {
  const navigate = useNavigate();
  const backHome = () => {
    navigate("/");
  };
  return (
    <div>
      <Result
        status="404"
        title="404"
        subTitle="链接异常"
        extra={
          <Button type="primary" onClick={backHome}>
            回首页
          </Button>
        }
      />
    </div>
  );
}
