import styled from "styled-components";
export const DivWrapper = styled.div`
  .site-layout-content {
    min-height: 280px;
    padding: 24px;
    background: #fff;
  }
  .logo {
    float: left;
    width: 180px;
    height: 41px;
    line-height: 41px;
    margin: 11px 24px 11px 0;
    background: rgba(255, 255, 255, 0.3);
    font-size: 24px;
    text-align: center;
    color: white;
  }
  .mainContent {
    min-height: calc(100vh - 64px - 70px - 48px);
    margin: 24px 50px;
    background: #fff;
  }
`;
