import axios from "axios";
import { message } from "antd";
const service = axios.create({
  // 设置baseUr地址
  baseURL: process.env.REACT_APP_API,
  // 定义统一的请求头部
  headers: {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
  },
  // 配置请求超时时间
  timeout: 10000,
  // 如果用的JSONP，可以配置此参数带上cookie凭证，如果是代理和CORS不用设置
  // withCredentials: true,
});
// 请求拦截
service.interceptors.request.use((config) => {
  // 自定义header，可添加项目token
  if (localStorage.getItem("userToken")) {
    config.headers.userToken = localStorage.getItem("userToken");
  }
  // config.headers.token = "token";
  return config;
});
// 返回拦截
service.interceptors.response.use(
  (response) => {
    if (response.data.code === 401) {
      window.location.href = "/login";
    }
    return response.data;
  },
  (err) => {
    message.error("服务器异常", 2);
    return Promise.reject(err.response);
  }
);
export default service;
