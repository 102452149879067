import qs from "qs";
import instance from "./interceptor";

function request(url, params, options = {}, method) {
  return new Promise((resolve, reject) => {
    let data = {};
    if (method === "get") data = { params };
    if (method === "post") data = { data: qs.stringify(params) };
    instance({
      url,
      method,
      ...data,
      ...options,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function get(url, params = {}, options = {}) {
  return request(url, params, options, "get");
}
export function post(url, params = {}, options = {}) {
  return request(url, params, options, "post");
}
