import React, { useState } from "react";
import { Upload, message, Spin } from "antd";
import { InboxOutlined } from "@ant-design/icons";

const { Dragger } = Upload;
export default function DataTransfer() {
  const [loading, setLoading] = useState(false);
  const props = {
    name: "file",
    accept: ".xlsx,.xls",
    multiple: false,
    headers: {
      userToken: localStorage.getItem("userToken"),
    },
    beforeUpload(f) {
      if (f.name.endsWith(".xls") || f.name.endsWith(".xlsx")) {
        setLoading(true);
        message.loading({
          content: `${f.name} 正在上传`,
          key: "uploadkey",
          duration: 0,
        });
        return true;
      } else {
        message.error(`${f.name} 文件格式错误`);
        return false;
      }
    },
    action: process.env.REACT_APP_API + "/api/v1/rubbish/upload",
    onChange(info) {
      const { status } = info.file;
      console.log(info);
      if (status !== "uploading") {
        setLoading(true);
      }
      if (status === "done") {
        message.success({
          content: `${info.file.name} 上传成功`,
          key: "uploadkey",
        });
        setLoading(false);
      } else if (status === "error") {
        message.error({
          content: `${info.file.name} 上传失败`,
          key: "uploadkey",
        });
        setLoading(false);
      } else if (status === "removed") {
        setLoading(false);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  return (
    <div>
      <Spin spinning={loading}>
        <Dragger {...props}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">单击或者拖拽文件这个这个区域来上传</p>
          <p className="ant-upload-hint">必须为excel文件并且按照固定的模板</p>
        </Dragger>
      </Spin>
    </div>
  );
}
